const ContrastedDesc = () => {
  return (
    <section>
      <div className="pt-[158px] max-lg:py-[45px] pb-[139.5px] max-xl:gap-[50px] max-sm:py-[24px] max-xl:justify-between max-2lg:gap-[20px] max-lg:flex-col max-lg:gap-[80px] max-sm:gap-[36px] flex gap-[216px]">
        <div className="flex flex-col gap-[23.5px]">
          <h1 className="w-[542px] max-sm:text-[24px] max-lg:w-full text-[36px] leading-[120%] letter-spacing-2">
            Contrasted Marketing, with its prowess in digital campaigns,
            recognized the need for a digital transformation that mirrored their
            vibrancy and innovation.
          </h1>
        </div>
        <div className="w-[440px] max-lg:w-[100%] max-md:w-full flex flex-col gap-[63px] max-sm:gap-[40px]">
          <div className="w-[342px] max-lg:w-full flex flex-col gap-[30px]">
            <div className="w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                Industry
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="bg-[#F2F2F2] max-sm:w-[81px] max-sm:text-sm w-[104px] h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
              Marketing
            </div>
          </div>
          <div className="flex flex-col gap-[30px]">
            <div className="w-[342px]  max-lg:w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                services provided
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex gap-[14px] flex-wrap w-[100%]">
              <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                technology enablement
              </div>
              <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                digital transformation
              </div>
              <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                User Experience
              </div>
              <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                CMS development
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContrastedDesc;
