const Details = () => {
  return (
    <>
      <div className="flex gap-[216px] max-sm:gap-[32px] max-lg:flex-col max-1xl:gap-[56px] max-lg:py-[45px] max-sm:py-[40px] pb-[85.5px] pt-[68.5px]">
        <div className="w-[542px] max-xl:w-[50%] max-lg:w-full max-lg:h-auto flex flex-col gap-[30px] max-sm:gap[20px]">
          <div className=" w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
            <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
              what we did
            </div>
            <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
          </div>
          <h1 className="w-[542px] max-sm:text-[24px] max-xl:w-full text-[36px] leading-[120%] letter-spacing-3">
            Leaf & Hive witnessed a significant uptick in online traffic and
            sales, marking a tangible return on their investment.
          </h1>
          <p className="mt-[2px] max-sm:w-full max-lg:w-[82%] text-base opacity-80 max-sm:mt-[4px]">
            We made sure that their digital presence stood as a testament to
            Leaf & Hive's commitment to innovation and quality. The digital
            transformation automated the tedious tasks, enabling them to focus
            on innovating, and disrupting the food and beverages industry.
          </p>
        </div>
        <div className="max-xl:w-full flex flex-col max-sm:gap-[24px] max-lg:mt-0 mt-[51.5px] flex-wrap gap-[40px]">
          <div className="w-[294px] flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[48px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              350,000+
            </h1>
            <p className="text-base opacity-80">annual users</p>
          </div>
          <div className="w-[292px] flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[48px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              20% Increase
            </h1>
            <p className="text-base opacity-80">in conversion rate</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Details;
