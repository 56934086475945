const Desc = () => {
  return (
    <section>
      <div className="pt-[57px] max-lg:py-[45px] pb-[92.5px] max-xl:gap-[50px] max-sm:py-[24px] max-xl:justify-between max-sm:gap-[36px] max-2lg:gap-[20px] max-lg:flex-col max-lg:gap-[80px] flex gap-[216px]">
        <div className="flex flex-col max-lg:gap-[24px] gap-[23.5px]">
          <h1 className="w-[542px] max-sm:text-[24px] mb-[21.5px] max-lg:gap-[21px] max-sm:mb-[12px] max-lg:w-full text-[36px] leading-[120%] letter-spacing-2">
            Studio Neat is a Texas-based design company founded in 2010 by Tom
            Gerhardt and Dan Provost. They specialize in creating simple,
            well-designed products that solve everyday problems.
          </h1>
        </div>
        <div className="w-[382px] max-lg:w-[100%] max-md:w-full flex flex-col gap-[63px] max-lg:gap-[40px]">
          <div className="flex flex-col gap-[30px]">
            <div className="max-lg:w-[342px] w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                Industry{" "}
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex justify-start items-start">
              <div className="bg-[#F2F2F2]  max-sm:text-sm px-[16px] py-[8px] flex justify-start h-[40px] text-[#000000] rounded-[8px] text-base max-sm:text-sm font-normal font-['Archivo']">
                Products{" "}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[30px]">
            <div className="max-lg:w-[342px] w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                services provided{" "}
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex gap-[14px] flex-wrap w-[100%]">
              <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                Product design{" "}
              </div>
              <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                Ecommerce Development{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Desc;
