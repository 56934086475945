import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import img from "../../cloud/assets/system-uicons_scale (1).svg";
import img2 from "../../cloud/assets/ph_note-pencil-light.svg";
import img3 from "../../cloud/assets/system-uicons_scale (2).svg";

const SFCS = () => {
  return (
    <>
      <>
        <div className="w-full h-[947px] pb-20 max-sm:pb-0 relative bg-white max-lg:h-full">
          <div className="left-[80px] mt-[168px] max-sm:mt-20  flex-col justify-start items-start gap-[22px] inline-flex">
            <div className="flex-col justify-start items-start gap-[30px] flex">
              <div className="w-[521.42px] justify-start items-center gap-[13.42px] inline-flex max-sm:w-full">
                <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                  Salesforce Services{" "}
                </div>
                <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
              </div>
              <div className="w-[562.77px]  letter-spacing-2 text-black text-[49.20px] font-normal font-['Graphik'] leading-[120%] max-sm:w-full max-sm:text-[36px]">
                Salesforce Consulting and Strategy{" "}
              </div>
            </div>
            <div className="w-[536.02px] h-[85.15px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-full max-sm:h-full">
              Unleash the full potential of your business with our Salesforce
              consulting. We strategize, customize, and optimize, ensuring a
              seamless digital transformation that propels your success to new
              heights.{" "}
            </div>
          </div>

          <div className="left-[80px] top-[401px] mt-[42px] flex-col justify-start items-start gap-[85px] inline-flex">
            <div className="justify-start items-start gap-[52px] inline-flex max-lg:flex-wrap">
              <div className="flex-col justify-start items-start gap-5 inline-flex">
                <img src={img} alt="" className="w-[57px] h-[57px]" />
                <div className="text-black letter-spacing-4 text-xl font-normal font-['Graphik']  w-[273.16px] h-[48px] max-sm:h-full max-sm:w-full leading-[120%]">
                  Salesforce Implementation Strategy{" "}
                </div>
                <div className="w-[273.16px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-[90%]">
                  Craft a tailored roadmap for seamless Salesforce
                  implementation, ensuring optimal configuration, user adoption,
                  and business process alignment.{" "}
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-5 inline-flex">
                <img src={img2} alt="" className="w-[57px] h-[57px]" />
                <div className="text-black letter-spacing-4 text-xl font-normal font-['Graphik']  w-[273.16px] h-[48px] max-sm:h-full max-sm:w-full leading-[120%]">
                  Salesforce Governance Consulting{" "}
                </div>
                <div className="w-[273.16px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-[90%]">
                  Establish robust governance policies, best practices, and
                  compliance frameworks to maximize the effectiveness of your
                  Salesforce ecosystem.{" "}
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-5 inline-flex">
                <img src={img3} alt="" className="w-[57px] h-[57px]" />
                <div className="text-black letter-spacing-4 text-xl font-normal font-['Graphik']  w-[273.16px] h-[48px] max-sm:h-full max-sm:w-full leading-[120%]">
                  Salesforce Adoption Planning{" "}
                </div>
                <div className="w-[273.16px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-[90%]">
                  Develop strategic adoption plans, training programs, and
                  change management strategies to enhance user engagement and
                  drive Salesforce platform utilization.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SFCS;
