import { useEffect, useState } from "react";
import axios from "axios";
const Details = () => {
  return (
    <>
      <div className="flex gap-[216px] max-sm:gap-[32px] max-lg:flex-col max-1xl:gap-[56px] max-lg:py-[45px] max-sm:py-[40px] pb-[68px] pt-[92px]">
        <div className="w-[542px] max-xl:w-[50%] max-lg:w-full max-lg:h-auto flex flex-col gap-[30px] max-sm:gap[20px]">
          <div className=" w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
            <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
              what we did
            </div>
            <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
          </div>
          <h1 className="w-[542px] max-sm:text-[24px] max-xl:w-full text-[36px] leading-[120%] letter-spacing-3">
            We started by immersing ourselves in Klub's vision, dissecting their
            requirements, and understanding the nuances of the investment
            landscape.{" "}
          </h1>
          <p className="max-sm:w-full max-lg:w-[82%] text-base opacity-80 max-sm:mt-[4px]">
            Keeping the user at the forefront, our design focused on simplicity
            without compromising functionality. A clean, intuitive interface
            that would make even a novice investor feel at home.{" "}
          </p>
        </div>
        <div className="max-xl:w-full flex flex-col max-sm:gap-[24px] max-lg:mt-0 mt-[51.5px] flex-wrap gap-[40px]">
          <div className="w-[327px] flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[48px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              150% Increase{" "}
            </h1>
            <p className="text-base opacity-80">
              in user adoption compared to their initial projections.{" "}
            </p>
          </div>
          <div className="w-[292px] flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[48px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              40% Increase{" "}
            </h1>
            <p className="text-base opacity-80">
              {" "}
              in the diversification of the users’ investment portfolios{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Details;
