const Desc = () => {
  return (
    <section>
      <div className="pt-[57px] max-lg:py-[45px] pb-[128px] max-xl:gap-[50px] max-sm:py-[24px] max-xl:justify-between max-2lg:gap-[20px] max-sm:gap-[36px  ] max-lg:flex-col max-lg:gap-[80px] flex gap-[216px]">
        <div className="flex flex-col gap-[24px]">
          <h1 className="w-[532px] mb-[56px] max-sm:mb-[12px] max-lg:mb-[21px] max-sm:text-[24px] max-lg:w-full text-[36px] leading-[120%] letter-spacing-2">
            Wear Well ensures that each creation is a manifestation of timeless
            elegance, meticulously curated to resonate with the ever-evolving
            standards of global fashion. Catering to leading brands like Zara,
            Massimo Dutti, Mango etc.
          </h1>
          <div className="flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[36px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              $30 Million
            </h1>
            <p className="text-base opacity-80">annual turnover</p>
          </div>
          <div className="flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[36px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              India’s leading exporter
            </h1>
            <p className="text-base opacity-80">of ready to wear garments</p>
          </div>
        </div>
        <div className="w-[440px] max-lg:w-[100%] max-md:w-full flex flex-col gap-[63px] max-sm:gap-[40px]">
          <div className="w-[342px] max-lg:w-full flex flex-col gap-[16px]">
            <div className=" w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                Industry
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="bg-[#F2F2F2] w-[167px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
              Retail and apparel
            </div>
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="w-[342px]  max-lg:w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                services provided
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex gap-[14px] flex-wrap w-[100%]">
            <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Design System
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                User Experience
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                experience design
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Brand development
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                product engineering
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Motion graphics
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                digital transformation
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="w-[342px]  max-lg:w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                applications delivered
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex gap-[14px] flex-wrap w-[100%]">
            <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Attendance and Leaves Management
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Payroll Management
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Approvals Management
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Appraisals Management
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                new joinee tool
              </div>
              <div className="bg-[#F2F2F2] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                Training Management
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Desc;
