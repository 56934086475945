const About = () => {
  return (
    <>
      <div className="max-md:w-full pt-[170px] pb-[207px] max-lg:py-[45px] max-sm:py-[40px] flex flex-col gap-[30px] max-sm:gap-[20px]">
        <div className="w-[609px] justify-start items-center gap-[13.42px] inline-flex max-lg:w-[100%]">
          <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
            problem statement{" "}
          </div>
          <div className=" grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
        </div>
        <h1 className="w-[1052px] max-3lg:w-full max-sm:text-[24px] text-[48px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
          Lyra, a company dedicated to making top-notch mental healthcare
          accessible, knew their user experience (UX) wasn't cutting it. Their
          website and app, crucial for connecting people with the help they
          need, felt clunky and confusing.{" "}
        </h1>
      </div>
    </>
  );
};
export default About;
