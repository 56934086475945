const Details = () => {
  return (
    <>
      <div className="flex gap-[216px] max-sm:gap-[32px] max-lg:flex-col max-1xl:gap-[56px] max-lg:py-[45px] max-sm:py-[40px] pb-[68px] pt-[92px]">
        <div className="w-[542px] max-xl:w-[50%] max-lg:w-full max-lg:h-auto flex flex-col gap-[30px] max-sm:gap[20px]">
          <div className=" w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
            <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
              what we did
            </div>
            <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
          </div>
          <h1 className="w-[542px] max-sm:text-[24px] max-xl:w-full text-[36px] leading-[120%] letter-spacing-3">
            By working with Codelinear, Middle has empowered individuals to take
            control of their home loan journey and achieve their dream of
            homeownership with confidence.{" "}
          </h1>
          <p className="max-sm:w-full max-lg:w-[82%] text-base opacity-80 max-sm:mt-[4px]">
            The new Middle platform is a game-changer. Users can now securely
            share all their financial information with their brokers in just
            minutes, eliminating the need for endless forms and insecure emails.{" "}
          </p>
        </div>
        <div className="max-xl:w-full flex flex-col max-sm:gap-[24px] max-lg:mt-0 mt-[51.5px] flex-wrap gap-[40px]">
          <div className="w-[327px] flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[48px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              25% Increase{" "}
            </h1>
            <p className="text-base opacity-80">
              in overall application volume.{" "}
            </p>
          </div>
          <div className="w-[322px] flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[48px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
              60% Decrease{" "}
            </h1>
            <p className="text-base opacity-80"> in application abandonment </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Details;
