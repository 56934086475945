import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import img1 from "../../cloud/assets/ph_clipboard-light (1).svg";
import img3 from "../../cloud/assets/system-uicons_scale (3).svg";
import img2 from "../../cloud/assets/system-uicons_scale (1).svg";

const Integrate = () => {
  return (
    <>
      <div className="w-full h- relative bg-white pb-[147px] max-sm:pb-0">
        <div className="left-[80px] mt-[168px] max-sm:mt-20  flex-col justify-start items-start gap-[22px] inline-flex">
          <div className="flex-col justify-start items-start gap-[30px] flex">
            <div className="w-[521.42px] justify-start items-center gap-[13.42px] inline-flex max-sm:w-full">
              <div className="opacity-60 text-black text-sm font-normal font-['Archivo'] uppercase leading-snug">
                Salesforce Services{" "}
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="w-[562.77px] letter-spacing-2 text-black text-[49.20px] font-normal font-['Graphik'] leading-[120%] max-sm:w-full max-sm:text-[36px]">
              Integration Services{" "}
            </div>
          </div>
          <div className="w-[536.02px] h-[85.15px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-full max-sm:h-full">
            Integrate seamlessly with our Salesforce Integration Services. Unify
            your ecosystem, break data silos, and enhance collaboration for a
            connected, streamlined, and future-ready business landscape.{" "}
          </div>
        </div>

        <div className="left-[80px] top-[401px] mt-[42px] flex-col justify-start items-start gap-[85px] inline-flex">
          <div className="justify-start items-start gap-[52px] inline-flex max-lg:flex-wrap max-lg:w-full">
            <div className="flex-col justify-start items-start gap-5 inline-flex">
              <img src={img1} alt="" className="w-[57px] h-[57px]" />
              <div className="text-black letter-spacing-4 text-xl font-normal font-['Graphik']  w-[273.16px] h-[48px] max-sm:h-full max-sm:w-full leading-[120%]">
                Third-Party Integration Solutions{" "}
              </div>
              <div className="w-[273.16px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-full">
                Seamlessly connect Salesforce with external systems,
                applications, and APIs for efficient data exchange and
                streamlined operations.{" "}
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-5 inline-flex">
              <img src={img2} alt="" className="w-[57px] h-[57px]" />
              <div className="text-black letter-spacing-4 text-xl font-normal font-['Graphik']  w-[273.16px] h-[48px] max-sm:h-full max-sm:w-full leading-[120%]">
                Custom API Development{" "}
              </div>
              <div className="w-[273.16px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-full">
                Design and implement custom APIs, ensuring smooth and secure
                integration between Salesforce and diverse business
                applications.
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-5 inline-flex">
              <img src={img3} alt="" className="w-[57px] h-[57px]" />
              <div className="text-black letter-spacing-4 text-xl font-normal font-['Graphik']  w-[273.16px] h-[48px] max-sm:h-full max-sm:w-full leading-[120%]">
                Data Synchronization Solutions{" "}
              </div>
              <div className="w-[273.16px] opacity-80 text-black text-base font-normal font-['Graphik'] leading-normal max-sm:w-full">
                Implement robust data synchronization mechanisms, ensuring
                real-time consistency and accuracy across integrated platforms
                within Salesforce environments.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Integrate;
